<template>
  <div>
    <v-container fluid class="py-8">
      <Loading v-if="loading"></Loading>
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col lg="8" cols="12">
          <v-card class="card-shadow border-radius-xl">
            <div class="border-bottom d-flex align-center px-4 py-4">
              <div class="d-flex align-center" v-if="ticket.ticket_customer">
                <a href="javascript:;" class="text-decoration-none">
                  <v-avatar size="48" rounded>
                    <v-img
                      v-if="ticket.ticket_customer.users.user_avatar"
                      :src="ticket.ticket_customer.users.user_avatar"
                      :lazy-src="ticket.ticket_customer.users.user_avatar"
                    >
                    </v-img>
                    <v-img v-else src="@/assets/img/no-avatar.png"> </v-img>
                  </v-avatar>
                </a>
                <div class="mx-4">
                  <a
                    href="javascript:;"
                    class="
                      text-dark
                      font-weight-600
                      text-sm text-decoration-none
                    "
                    >{{ ticket.ticket_customer.users.user }}</a
                  >
                  <small class="d-block text-muted">{{
                    ticket.created_at
                  }}</small>
                </div>
              </div>
              <div class="text-end ms-auto">
                <v-btn
                  v-if="ticket.status == 1"
                  icon
                  color="red"
                  @click="get_comment()"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
                <v-chip
                  label
                  :color="color_importance_level"
                  class="py-1 px-6 my-0 text-white"
                >
                  {{ ticket.importance_level_text }}
                </v-chip>
              </div>
            </div>
            <div class="px-4 py-4" v-if="ticket.ticket_subjects">
              <div class="flex"></div>
              <h3 class="text-h4 text-typo font-weight-bold">
                {{ ticket.ticket_subjects.subject }}
              </h3>
              <p class="mb-6 text-body">
                {{ ticket.content }}
              </p>
              <v-img
                v-if="ticket.ticket_attachments"
                :src="ticket.ticket_attachments.path"
                class="border-radius-lg shadow-lg"
              >
              </v-img>
              <div class="mb-5">
                <hr class="horizontal dark mt-7 mb-5" />
                <div
                  class="d-flex mb-3"
                  v-for="(comment, index) in ticket.ticket_comments"
                  :key="index"
                >
                  <div class="flex-shrink-0">
                    <v-avatar v-if="comment.ticket_admins">
                      <v-img
                        v-if="comment.ticket_admins.users.user_avatar"
                        :src="comment.ticket_admins.users.user_avatar"
                        :lazy-src="comment.ticket_admins.users.user_avatar"
                        class="w-100 border-radius-lg shadow-lg"
                      >
                      </v-img>
                      <v-img
                        v-else
                        class="w-100 border-radius-lg shadow-lg mt-4"
                        src="@/assets/img/no-avatar.png"
                      >
                      </v-img>
                    </v-avatar>

                    <v-avatar v-else-if="comment.ticket_customer">
                      <v-img
                        v-if="comment.ticket_customer.users.user_avatar"
                        :src="comment.ticket_customer.users.user_avatar"
                        :lazy-src="comment.ticket_customer.users.user_avatar"
                        class="w-100 border-radius-lg shadow-lg"
                      >
                      </v-img>
                      <v-img
                        v-else
                        class="w-100 border-radius-lg shadow-lg mt-4"
                        src="@/assets/img/no-avatar.png"
                      >
                      </v-img>
                    </v-avatar>
                  </div>
                  <div class="flex-grow-1 ms-4">
                    <div v-if="comment.ticket_admins">
                      <h5 class="text-h6 text-typo font-weight-bold mt-0 mb-2">
                        {{ comment.ticket_admins.users.user }}
                      </h5>
                      <p class="text-sm text-body">
                        {{ comment.comment }}
                      </p>
                      <div v-if="comment.comment_attachments">
                        <a
                          :href="comment.comment_attachments.path"
                          target="__blank"
                          class="text-decoration-none"
                        >
                          <img
                            width="200"
                            v-if="comment.comment_attachments.is_image == 1"
                            :src="comment.comment_attachments.path"
                            alt=""
                          />
                          <v-chip
                            v-if="comment.comment_attachments.is_image == 0"
                            label
                            color="gray"
                            class="py-1 pl-6 pr-10 my-0"
                          >
                            <v-icon size="18" class="rotate-180"
                              >fas fa-file-pdf text-lg me-1</v-icon
                            >
                            {{ $t("ticket.file") }}
                            <!-- {{ comment.comment_attachments.extension }} -->
                          </v-chip>
                        </a>
                      </div>
                    </div>
                    <div v-else-if="comment.ticket_customer">
                      <h5 class="text-h6 text-typo font-weight-bold mt-0 mb-2">
                        {{ comment.ticket_customer.users.user }}
                      </h5>
                      <div>
                        <p class="text-sm text-body">
                          {{ comment.comment }}
                        </p>
                        <div v-if="comment.comment_attachments">
                          <a
                            :href="comment.comment_attachments.path"
                            target="__blank"
                            class="text-decoration-none"
                          >
                            <img
                              width="200"
                              v-if="comment.comment_attachments.is_image == 1"
                              :src="comment.comment_attachments.path"
                              alt=""
                            />
                            <v-chip
                              v-if="comment.comment_attachments.is_image == 0"
                              label
                              color="gray"
                              class="py-1 pl-6 pr-10 my-0"
                            >
                              <v-icon size="18" class="rotate-180"
                                >fas fa-file-pdf text-lg me-1</v-icon
                              >
                              <!-- {{comment.comment_attachments.extension}} -->
                              {{ $t("ticket.file") }}
                            </v-chip>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex mt-6" v-if="ticket.status == 1">
                  <div v-if="ticket.ticket_customer" class="flex-shrink-0">
                    <v-avatar>
                      <!-- <v-img
                        v-if="ticket.ticket_customer.users.avatar"
                        :src="ticket.ticket_customer.users.avatar"
                        :lazy-src="ticket.ticket_customer.users.avatar"
                      >
                      </v-img> -->
                      <!-- <v-img v-else src="@/assets/img/no-avatar.png"> </v-img> -->
                      <v-img src="@/assets/img/no-avatar.png"> </v-img>
                      <!-- <v-img src="@/assets/img/team-4.jpg"></v-img> -->
                    </v-avatar>
                  </div>
                  <div class="flex-grow-1 ml-4 mr-2">
                    <div class="d-flex position-relative">
                      <v-text-field
                        outlined
                        hide-details
                        :placeholder="$t('ticket.Write your comment')"
                        class=""
                        v-model="form_comment.comment"
                      >
                      </v-text-field>
                      <v-file-input
                        class="file-input"
                        outlined
                        v-model="form_comment.attachment"
                        @change="attachmentUplaod"
                      ></v-file-input>
                    </div>
                    <div v-if="error.comment">
                      <p class="font-error text-danger">
                        {{ error.comment }}
                      </p>
                    </div>
                    <div
                      v-if="box_attach.type == 'file'"
                      :class="
                        box_attach.type == 'file'
                          ? 'position-relative mt-2 text-center'
                          : 'position-relative mt-2'
                      "
                    >
                      <v-chip
                        v-if="box_attach.type == 'file'"
                        label
                        color="gray"
                        class="py-1 pl-6 pr-10 my-0"
                      >
                        <button
                          @click="removeImage()"
                          :class="
                            box_attach
                              ? 'btn-circle d-inline-block'
                              : 'btn-circle'
                          "
                        >
                          <v-icon class="text-white close">mdi-close</v-icon>
                        </button>
                        <span class="text-caption ls-0">{{
                          box_attach.file
                        }}</span>
                      </v-chip>
                    </div>
                  </div>
                  <div class="flex-shrink-0">
                    <v-checkbox
                      class="mt-1"
                      :class="classLang"
                      v-model="form_comment.hidden"
                      :color="'green'"
                      hide-details
                      :label="$t('ticket.hidden')"
                      :value="form_comment.hidden"
                    ></v-checkbox>
                  </div>
                  <div class="flex-shrink-0">
                    <v-btn
                      outlined
                      color="#fff"
                      class="font-weight-bolder bg-gray px-5 btn-send"
                      small
                      :loading="load_comment"
                      @click="sendComment"
                      v-if="
                        $store.getters['auth/str_per'].indexOf(
                          'ticket-send-comment'
                        ) > -1
                      "
                    >
                      <span slot="loader">
                        <v-icon size="15" color="white">mdi-send</v-icon>
                      </span>
                      <v-icon size="15">mdi-send</v-icon>
                    </v-btn>
                  </div>
                </div>
                <v-row class="mt-3">
                  <v-col cols="12" md="5" class="pt-0">
                    <div
                      v-if="box_attach.type == 'image'"
                      :class="
                        box_attach.type == 'image'
                          ? 'position-relative d-inline-block'
                          : 'position-relative'
                      "
                    >
                      <!-- <div class="position-relative"> -->
                      <button
                        @click="removeImage()"
                        :class="
                          box_attach
                            ? 'btn-circle d-inline-block'
                            : 'btn-circle'
                        "
                      >
                        <v-icon class="text-white">mdi-close</v-icon>
                      </button>
                      <v-img
                        :src="box_attach.file"
                        :lazy-src="box_attach.file"
                        width="100"
                        class="border-radius-lg shadow-lg"
                      >
                      </v-img>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col lg="4" cols="12" v-if="ticket.status == 1">
          <!-- close ticket -->
          <v-card
            v-if="$store.getters['auth/str_per'].indexOf('ticket-close') > -1"
            class="card-shadow border-radius-xl mb-6"
          >
            <div class="px-4 py-4">
              <h5
                class="text-h5 font-weight-bold mb-1 text-gradient text-danger"
              >
                <a href="javascript:;" class="text-decoration-none">{{
                  $t("ticket.Close Ticket")
                }}</a>
              </h5>
              <p class="text-body mt-3">{{ $t("ticket.note close") }}</p>

              <div class="d-flex justify-end">
                <v-btn
                  :elevation="0"
                  color="#cb0c9f"
                  class="font-weight-bolder py-4 px-8 btn-primary"
                  small
                  :loading="load_close"
                  @click="closeTicket"
                >
                  <span slot="loader">
                    <v-progress-circular
                      style="width: 20px; height: 20px"
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                    {{ $t("form.Closing") }}
                  </span>
                  {{ $t("ticket.Close Ticket") }}
                </v-btn>
              </div>
            </div>
          </v-card>
          <!-- forword ticket -->
          <v-card
            v-if="$store.getters['auth/str_per'].indexOf('ticket-forword') > -1"
            class="card-shadow border-radius-xl mb-6"
          >
            <div class="px-4 py-4">
              <h5
                class="text-h5 font-weight-bold mb-1 text-gradient text-danger"
              >
                <a href="javascript:;" class="text-decoration-none">{{
                  $t("ticket.Forword")
                }}</a>
              </h5>
              <div class="">
                <label class="text-xs text-typo font-weight-bolder ms-1">{{
                  $t("ticket.Admin")
                }}</label>
                <v-autocomplete
                  v-model="form.to_admin_id"
                  :items="admins"
                  :item-text="'users.user'"
                  item-value="id"
                  :label="$t('general.choose')"
                  :error-messages="error.admin"
                  dense
                  outlined
                ></v-autocomplete>
              </div>
              <hr class="horizontal dark mb-4" />
              <div class="d-flex justify-end">
                <v-btn
                  :elevation="0"
                  color="#cb0c9f"
                  class="font-weight-bolder py-4 px-8 btn-primary"
                  small
                  :loading="load_forword"
                  @click="ForwordTicket"
                >
                  <span slot="loader">
                    <v-progress-circular
                      style="width: 20px; height: 20px"
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                  </span>
                  {{ $t("form.save") }}
                </v-btn>
              </div>
            </div>
          </v-card>

          <!-- change importance level -->
          <v-card
            v-if="
              $store.getters['auth/str_per'].indexOf(
                'ticket-change-importance-level'
              ) > -1
            "
            class="card-shadow border-radius-xl mb-6"
          >
            <div class="px-4 py-4">
              <h5
                class="text-h5 font-weight-bold mb-1 text-gradient text-danger"
              >
                <a href="javascript:;" class="text-decoration-none">{{
                  $t("ticket.importance_level")
                }}</a>
              </h5>
              <div class="">
                <label class="text-xs text-typo font-weight-bolder ms-1">{{
                  $t("ticket.importance_level")
                }}</label>
                <v-select
                  v-model="form_level.importance_level"
                  :items="importance_level_items"
                  :item-text="'name'"
                  item-value="id"
                  :label="$t('general.choose')"
                  :error-messages="error.importance_level"
                  dense
                  :rules="[(v) => !!v || this.$i18n.t('form.Item is required')]"
                  outlined
                ></v-select>
              </div>
              <hr class="horizontal dark mb-4" />
              <div class="d-flex justify-end">
                <v-btn
                  :elevation="0"
                  color="#cb0c9f"
                  class="font-weight-bolder py-4 px-8 btn-primary"
                  small
                  :loading="load_importance_level"
                  @click="importanceLevelTicket"
                >
                  <span slot="loader">
                    <v-progress-circular
                      style="width: 20px; height: 20px"
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                  </span>
                  {{ $t("form.save") }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Notify from "../Components/Old/Notify.vue";
import Loading from "../Components/Loading.vue";
import TicketService from "../../services/ticket.service";
export default {
  name: "Ticket-Page",
  components: {
    Notify,
    Loading,
  },
  data() {
    return {
      form: { to_admin_id: "", ticket_id: this.$route.params.id },
      form_comment: {
        comment: "",
        hidden: false,
        ticket_id: this.$route.params.id,
        attachment: null,
      },
      form_level: { importance_level: "", ticket_id: this.$route.params.id },
      load_importance_level: false,
      importance_level_items: [
        { id: 1, name: this.$i18n.t("ticket.low") },
        { id: 2, name: this.$i18n.t("ticket.meduim") },
        { id: 3, name: this.$i18n.t("ticket.height") },
      ],
      error: { admin: "", importance_level: "", comment: "" },
      loading: true,
      load_close: false,
      load_forword: false,
      load_comment: false,
      message: {
        msg: null,
        type: null,
      },
      ticket: {},
      admins: [],
      id: this.$route.params.id,
      box_attach: { type: "", file: "" },
    };
  },
  watch: {
    $route: function () {
      // alert(this.$route.params.id);
      window.location.reload();
    },
  },
  computed: {
    color_importance_level() {
      if (this.ticket.importance_level == 1) {
        return "orange";
      } else if (this.ticket.importance_level == 2) {
        return "green";
      } else if (this.ticket.importance_level == 3) {
        return "red";
      }
    },
    classLang() {
      return {
        "ml-2": this.$vuetify.rtl,
        "mr-2": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    get_detail() {
      return TicketService.get_detail_ticket(this.$route.params.id).then(
        (response) => {
          // console.log(response);
          this.ticket = response.data.ticket;
          this.admins = response.data.admins;

          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          if (error.response.status == 404) {
            this.$router.push({
              name: "TicketPage",
              params: {
                message: {
                  msg: error.response.data.message,
                  type: "Warning",
                },
              },
            });
          } else {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
    closeTicket() {
      this.load_close = true;
      return TicketService.close_ticket(this.$route.params.id).then(
        (response) => {
          // console.log(response);
          this.load_close = false;
          this.ticket.status = 0;
          this.message = {
            msg: response.data.message,
            type: "Success",
          };
        },
        (error) => {
          console.log(error);
          this.load_close = false;
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
    ForwordTicket() {
      this.load_forword = true;
      return TicketService.forword_ticket(this.form).then(
        (response) => {
          // console.log(response);
          this.load_forword = false;
          this.$router.push({
            name: "TicketPage",
            params: {
              message: { msg: response.data.message, type: "Success" },
            },
          });
        },
        (error) => {
          console.log(error);
          this.load_forword = false;
          if (error.response.status == 422) {
            var errors = error.response.data.errors;
            this.error.admin = errors.to_admin_id;
          } else {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
    importanceLevelTicket() {
      this.load_importance_level = true;
      return TicketService.importance_level_ticket(this.form_level).then(
        (response) => {
          // console.log(response);
          this.load_importance_level = false;
          this.ticket.importance_level = response.data.importance_level;
          this.ticket.importance_level_text =
            response.data.importance_level_text;
          this.error.importance_level = "";
          // this.form_level.importance_level = "";
          this.message = {
            msg: response.data.message,
            type: "Success",
          };
        },
        (error) => {
          console.log(error);
          this.load_importance_level = false;
          if (error.response.status == 422) {
            var errors = error.response.data.errors;
            this.error.importance_level = errors.importance_level;
          } else {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
    sendComment() {
      this.load_comment = true;
      const formData = new FormData();
      formData.append("attachment", this.form_comment.attachment);
      formData.append("comment", this.form_comment.comment);
      formData.append("hidden", this.form_comment.hidden);
      formData.append("ticket_id", this.form_comment.ticket_id);
      return TicketService.send_comment(formData).then(
        (response) => {
          // console.log(response);
          this.load_comment = false;
          if (response.data.comment) {
            this.ticket.ticket_comments.push(response.data.comment);
            this.form_comment = {
              comment: "",
              hidden: false,
              ticket_id: this.$route.params.id,
              attachment: null,
            };
            this.box_attach = { type: "", file: "" };
          }

          this.message = {
            msg: response.data.message,
            type: "Success",
          };
        },
        (error) => {
          console.log(error);
          this.load_comment = false;
          if (error.response.status == 422) {
            var errors = error.response.data.errors;
            this.error.comment = errors.comment;
          }
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
    attachmentUplaod() {
      console.log(event);
      this.form_comment.attachment = event.target.files[0];
      if (event.target.files[0].type.indexOf("image") != -1) {
        this.box_attach.type = "image";
        this.box_attach.file = URL.createObjectURL(event.target.files[0]);
      } else {
        this.box_attach.file =
          "file " + event.target.files[0].name + " has uploded";
        this.box_attach.type = "file";
      }
    },
    removeImage() {
      this.box_attach.file = "";
      this.box_attach.type = "";
      this.form.attachment = "";
    },
    get_comment() {
      // this.load_importance_level = true;
      return TicketService.get_comment(this.$route.params.id).then(
        (response) => {
          // console.log(response);
          for (let i = 0; i < response.data.comments.length; i++) {
            const element = response.data.comments[i];
            let found = this.ticket.ticket_comments.some(
              (el) => el.id == element.id
            );
            if (!found) this.ticket.ticket_comments.push(element);
          }
          console.log("ticket.ticket_comments");
          console.log(this.ticket.ticket_comments);

          // this.ticket.ticket_comments = response.data.comments;
          // this.message = {
          //   msg: response.data.message,
          //   type: "Success",
          // };
        },
        (error) => {
          console.log(error);
          // this.load_importance_level = false;
          if (error.response.status == 404) {
            // this.message = {
            //   msg: response.data.message,
            //   type: "Info",
            // };
          } else {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
  },
  mounted() {
    this.get_detail();
  },
};
</script>
  <style lang="css">
.btn-send {
  border-radius: 50%;
  width: 40px;
  height: 45px !important;
}
html:lang(ar) .btn-send.v-btn > .v-btn__content .v-icon {
  transform: rotate(180deg);
}
.file-input {
  position: absolute;
  right: 0;
}
html:lang(ar) .file-input {
  right: initial;
  left: 0;
}
.file-input.v-text-field--outlined fieldset,
.file-input.v-text-field--outlined.v-input--is-focused fieldset,
.file-input.v-text-field--outlined.v-input--has-state fieldset {
  border-width: 0px;
}
.file-input.v-text-field--outlined .v-input__prepend-outer,
.file-input.v-text-field--outlined .v-input__append-outer {
  margin-top: 8px;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0;
}
.file-input.v-text-field .v-input__control {
  display: none;
}
.btn-circle {
  line-height: 0;
  border-radius: 50%;
  background-color: #aaa !important;
  width: 23px;
  height: 23px;
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
}
.v-chip .v-icon.close {
  font-size: 15px;
}
.font-error {
  font-size: 13px;
}
</style>